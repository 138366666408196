<template>
  <div class="index_container agreement">
    <ItemScroll>
      <div class="agreement_main">
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
            text-align: center;
          "
        >
          <strong
            ><span style="font-size: 18px; color: #333333"
              >骨转百讯隐私政策概要</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >骨转百讯非常注重保护用户（</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >“</span
          ><span style="font-size: 14px; color: #333333">您</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >”</span
          ><span style="font-size: 14px; color: #333333"
            >）的个人信息及隐私，并希望通过本概要向您简洁介绍我们如何收集、使用和保护您的个人信息。如您需要了解完整的《</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          ><span style="font-size: 14px; color: #333333"
            >骨转百讯隐私政策》，可查阅概要后的隐私政策正文。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >我们如何收集和使用您的个人信息</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >为协助您登录骨转百讯账号，提供您开启的骨转百讯全面的服务，您需要向我们提供<strong>账号名称、密码、手机号码、电子邮箱</strong>。如您需要使用某些特定功能（例如医疗人士认证、信息展示及信息搜索浏览、信息公开发布等），您可能还需要向我们提供<strong>身份证号码、支付账号、个人地址信息等敏感个人信息以及您的昵称、头像、发布内容（包括文字、图片、视频等）、设备信息等个人信息</strong>，届时我们会明确向您告知收集使用该等信息的目的和必要性。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >在您使用骨转百讯的过程中，我们还会记录您的使用行为信息（例如您访问过的页面或者点骨转百讯的稳定运行，并向您展示您可能感兴趣的信息。该等信息通常不能直接识别您的个人身份。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >更多详情请见下方的个人信息收集清单</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
            text-align: center;
          "
        >
          <strong><span style="color: #333333">个人信息收集清单</span></strong>
        </p>
        <table cellspacing="0" cellpadding="0">
          <thead>
            <tr class="firstRow">
              <td
                width="181"
                style="background: rgb(245, 240, 255); padding: 1px"
              >
                <p
                  style="
                    margin-right: 0;
                    margin-left: 0;
                    font-size: 16px;

                    text-align: center;
                  "
                >
                  <strong
                    ><span style="font-size: 14px; color: #333333"
                      >场景</span
                    ></strong
                  ><strong
                    ><span
                      style="
                        font-size: 14px;

                        color: #333333;
                      "
                      >/</span
                    ></strong
                  ><strong
                    ><span style="font-size: 14px; color: #333333"
                      >业务功能</span
                    ></strong
                  >
                </p>
              </td>
              <td
                width="191"
                style="background: rgb(245, 240, 255); padding: 1px"
              >
                <p
                  style="
                    margin-right: 0;
                    margin-left: 0;
                    font-size: 16px;

                    text-align: center;
                  "
                >
                  <strong
                    ><span style="font-size: 14px; color: #333333"
                      >个人信息种类</span
                    ></strong
                  >
                </p>
              </td>
              <td
                width="181"
                style="background: rgb(245, 240, 255); padding: 1px"
              >
                <p
                  style="
                    margin-right: 0;
                    margin-left: 0;
                    font-size: 16px;

                    text-align: center;
                  "
                >
                  <strong
                    ><span style="font-size: 14px; color: #333333"
                      >目的</span
                    ></strong
                  >
                </p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="181" rowspan="2" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333">登录</span>
                </p>
              </td>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >手机号、验证码；账号、密码</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用户完善账号信息</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >头像、昵称、个人简介</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用于完善网络身份标识</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >医疗行业专业用户认证</span
                  >
                </p>
              </td>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >姓名、手机号、医院</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    >/</span
                  ><span style="font-size: 14px; color: #333333">学校</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    >/</span
                  ><span style="font-size: 14px; color: #333333"
                    >单位、科室</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    >/</span
                  ><span style="font-size: 14px; color: #333333">专业</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    >/</span
                  ><span style="font-size: 14px; color: #333333"
                    >部门、职称</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    >/</span
                  ><span style="font-size: 14px; color: #333333"
                    >学历、职务、入学年份、身份证号</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用于完成医疗行业专业认证</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333">发布内容</span>
                </p>
              </td>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >头像、昵称、及发布内容（包括文字、图片、视频等）</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用于展示用户发布的帖子、回帖、点赞、收藏、直播交流等内容</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333">搜索浏览</span>
                </p>
              </td>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用户在访问及使用过程中产生的点击、浏览、关注、点赞、收藏、转发、分享、搜索等产生的信息</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >基于用户的行为信息，在对应的位置为用户展示对应的内容，如最近浏览、关注、收藏夹、首页关注、历史搜索等等</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >使用过程中</span
                  >
                </p>
              </td>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >设备信息、日志信息、网络信息</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用户安全保障</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >客服与售后</span
                  >
                </p>
              </td>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用户与客服的联系记录、账号信息、订单信息等客服解决问题所必要的信息</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >用于客服与售后服务与争议处理</span
                  >
                </p>
              </td>
            </tr>
            <tr>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333">附加功能</span>
                </p>
              </td>
              <td width="191" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >相机、相册、麦克风、系统通知</span
                  >
                </p>
              </td>
              <td width="181" style="padding: 1px">
                <p style="font-size: 16px">
                  <span style="font-size: 14px; color: #333333"
                    >相机：扫码、拍照片、拍摄视频</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    ><br /></span
                  ><span style="font-size: 14px; color: #333333"
                    >相册：从相册中上传图片、视频</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    ><br /></span
                  ><span style="font-size: 14px; color: #333333"
                    >麦克风：拍摄视频</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    ><br /></span
                  ><span style="font-size: 14px; color: #333333"
                    >系统通知：主动给用户推送信息</span
                  ><span
                    style="
                      font-size: 14px;

                      color: #333333;
                    "
                    ><br /></span
                  ><span style="font-size: 14px; color: #333333"
                    >上述附加功能仅在特定场景下使用时才会申请询问，若用户拒绝不影响用户使用基本功能</span
                  >
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>

        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >我们需要开启哪些设备权限</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >我们的部分功能可能会调用您设备上的一些权限，包括向我们开启您的相机（摄像头）、相册（图片库）、麦克风等访问权限，</span
            ></strong
          ><strong> </strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >以实现这些功能所涉及的信息的收集和使用</span
            ></strong
          ><span style="font-size: 14px; color: #333333"
            >。<strong>在您使用相应功能时会看到弹窗提醒，询问您是否授权。您可以到您的设备的【设置】中</strong>找到关于</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >“</span
          ><span style="font-size: 14px; color: #333333">骨转百讯</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >”</span
          ><span style="font-size: 14px; color: #333333"
            >的隐私设置情况逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭（我们会指引您在您的设备系统中完成设置）。</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >我们如何共享您的信息</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >在我们向您提供服务时，我们会采取严格的安全措施，由我们所完成的服务中我们不会与骨转百讯以外的任何公司、组织和个人共享您的个人信息。<strong>但请您知悉，我们提供的服务有部分功能是无法单独完成的，因此我们可能会与我们的关联公司以及第三方合作商【上海医飞科技有限公司】（以下简称“第三方”）共享或委托其处理您的部分个人信息</strong>，以保障和优化我们为您提供的服务。<strong>第三方【上海医飞科技有限公司】负责本次【骨转百讯】平台搭建及具体执行。同时，我们也会严格督促与第三方的合作信息。</strong>我们与第三方共享的服务类型一般包括：商品或技术服务类、分析服务类、第三方商家、统计类、厂商推送类。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协议和</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >/</span
          ><span style="font-size: 14px; color: #333333"
            >或个人信息保护协议，要求他们按照国家相关法律法规规范等的要求，我们的说明，本隐私政策以及其他任何有关个人信息保密和安全保护等措施来处理个人信息。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >您如何管理您的信息</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >您有权管理您的个人信息，包括访问、更正和删除您的个人信息，改变您授权同意的范围或撤回授权，以及注销您的账号。您可以通过手机设置或骨转百讯中提供的功能设置进行上述操作。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >如果您无法通过上述方式进行管理，您可以通过我们以下提供的联系方式联系我们，我们将在</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >15</span
          ><span style="font-size: 14px; color: #333333"
            >个工作日内回复您的要求。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >我们如何做好未成年人保护</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333">我们非常重视未满</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >18</span
          ><span style="font-size: 14px; color: #333333"
            >周岁的未成年人（特别是未满</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >14</span
          ><span style="font-size: 14px; color: #333333"
            >周岁的儿童）的个人信息的保护，我们已专门设置了未成年人保护章节并专门制定了《儿童个人信息保护政策》。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >由于我们的产品和服务主要面向成人，如您为未成年人的，请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >如何联系我们</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >1</span
          ><span style="font-size: 14px; color: #333333"
            >、如果您认为您的个人信息安全有被侵害或泄露风险的，您可以通过以下方式进行投诉、举报：可以发送邮件至&nbsp;yifeikeji@medflying.com。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >2</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、为保障我们高效处理您的问题并及时向您反馈，根据具体问题的要求可能会需要您提交身份证明、</span
            ></strong
          ><strong> </strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >有效联系方式和书面请求及相关证据，我们会在验证您的身份后，处理您的请求。</span
            ></strong
          ><strong> </strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >一般情况下，我们将在</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
            >
              15
            </span></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >个工作日内完成处理。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;
            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333">&nbsp;</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;
            text-wrap: wrap;
            text-align: center;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >「骨转百讯」隐私政策</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;
            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >版本生效日期：2023年11月25日</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;
            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >欢迎您使用骨转百讯平台的产品和/或服务。我们非常重视和保护您的隐私信息和个人信息保护。</span
          >
          <span style="font-size: 14px; color: #333333"
            >【骨转百讯】平台是指由<strong>【中关村精准医学基金会】</strong
            ><span>享有所有权和</span></span
          ><span style="font-size: 14px; color: #333333; background: white"
            >/</span
          ><span style="font-size: 14px; color: #333333; background: white"
            >或运营权的平台或客户端服务的统称</span
          ><span style="font-size: 14px; color: #333333">，以下称为</span
          ><span style="font-size: 14px; color: #333333">”</span
          ><span style="font-size: 14px; color: #333333">我们</span
          ><span style="font-size: 14px; color: #333333">“</span
          ><span style="font-size: 14px; color: #333333">或</span
          ><span style="font-size: 14px; color: #333333">”</span
          ><span style="font-size: 14px; color: #333333">平台</span
          ><span style="font-size: 14px; color: #333333">“</span
          ><span style="font-size: 14px; color: #333333">。</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;
            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >本隐私政策适用于您通过任何方式使用骨转百讯平台提供的全部产品和</span
          ><span style="font-size: 14px; color: #333333">/</span
          ><span style="font-size: 14px; color: #333333"
            >或服务（本隐私政策中统称为骨转百讯平台服务）而提供或留存的信息，我们希望通过本隐私政策向您说明在您使用骨转百讯平台服务时，我们是如何收集、使用、保存、共享和转让这些信息，以及我们将为您提供访问、更新、删除、保护以及注销这些信息的方式。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;
            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >请您在继续使用骨转百讯产品或服务前务必认真仔细阅读并确认充分理解本隐私政策全部规则和要点，并确认您已经充分理解本隐私政策所写明的内容（重点内容我们均用粗体或下划线方式表示）。如您不同意本政策内容，将可能导致我们的产品或服务无法进行，或无法达到我们拟达到的效果，您应立即停止访问</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333">/</span></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >使用我们的产品或服务。您继续使用我们的产品或服务的行为，都表示您充分理解和同意本《骨转百讯隐私政策》（包括更新版本）的全部内容。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;
            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >请您注意，本隐私政策不适用于您通过我们的产品或服务而接入的其他第三方产品</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333">/</span></strong
          ><strong
            ><span style="font-size: 14px; color: #333333">服务</span></strong
          ><strong
            ><span style="font-size: 14px; color: #333333">(“</span></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >其他第三方</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333">”</span></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >，包括您的交易相对方、任何第三方网站以及第三方服务提供者等</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333">)</span></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >，第三方服务受其自有隐私政策约束，你在选择使用第三方服务前应充分了解第三方服务的产品功能及隐私保护政策。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >如您对本隐私政策或相关事宜有任何疑问，可通过本隐私政策第八章节【如何联系我们】向我们进行反馈。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >本隐私政策帮助您了解以下内容：</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px"
            >一、我们如何收集和使用您的个人信息；</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">二、我们如何使用</span
          ><span style="font-size: 14px"> Cookie </span
          ><span style="font-size: 14px">和其他追踪技术；</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px"
            >三、我们如何委托处理、共享、转让、公开披露您的个人信息；</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px"
            >四、我们如何保存和保护您的个人信息；</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">五、您的权利；</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">六、未成年人保护；</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">七、如何更新本政策；</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">八、如何联系我们；</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">九、争议解决；</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">十、定义和名词解释。</span>
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >一、我们如何收集和使用您的个人信息</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333">前言：</span></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >1</span
          ><span style="font-size: 14px; color: #333333"
            >、我们会遵循合法、正当、最小必要、公开透明的原则，收集和使用您在使用我们的产品和</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >/</span
          ><span style="font-size: 14px; color: #333333"
            >或服务过程中主动提供或因使用我们的产品和</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >/</span
          ><span style="font-size: 14px; color: #333333"
            >或服务而产生的个人信息。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >2</span
          ><span style="font-size: 14px; color: #333333"
            >、当您使用我们的产品时，您的系统权限将处于默认关闭状态。我们仅在您触发相关业务功能时，向您申请实现该等业务功能所必需的权限（我们将在本隐私保护指引中对具体申请调用的权限以粗体或斜体标注以提示您注意）。我们在申请权限时将向您同步告知权限申请目的。您可以随时撤回授权任一系统权限。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >3</span
          ><span style="font-size: 14px; color: #333333"
            >、需要特别指出的是，即使经过您的授权，可以访问您的相应权限，我们仅会在您的授权范围内进行访问；且如您拒绝或撤回授予系统权限，仅会使您无法使用相应功能，但并不影响您正常使用产品与</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >/</span
          ><span style="font-size: 14px; color: #333333"
            >或服务的其他功能。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >4</span
          ><span style="font-size: 14px; color: #333333"
            >、我们收集的您的个人信息类型会因产品</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >/</span
          ><span style="font-size: 14px; color: #333333"
            >服务的内容不同而有所区别，具体以产品</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >/</span
          ><span style="font-size: 14px; color: #333333"
            >服务实际提供为准。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >5</span
          ><span style="font-size: 14px; color: #333333"
            >、我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，这意味着随着产品和服务的迭代、拓展、升级等，我们可能会经常推出新的业务功能或重新划分业务功能，并可能需要收集新的个人信息或变更个人信息使用目的或方式。<strong
              >如果我们新增涉及个人信息收集的功能或产品</strong
            ></span
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >/</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >服务，或变更个人信息的使用目的或方式，根据具体情况，我们会通过更新本隐私保护指引、页面提示、弹窗、网站公告、站内信等方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在征得您同意后收集和使用。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >6</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将不含可识别到具体个人身份的信息。</span
            ></strong
          ><strong> </strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >请您了解并同意，在此情况下我们有权使用已经去标识化的信息；</span
            ></strong
          ><strong> </strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >7</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、我们会对我们的产品与</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >/</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，</span
            ></strong
          ><strong> </strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >以展示我们的产品与</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >/</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >或服务的整体使用趋势。但这些统计信息将进行匿名化处理，不包含您的任何身份识别信息。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >8</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、对于您选择在我们平台发布非匿名信息，如发布帖子和评论等，视为您同意在平台向其他用户公开展示您的个人信息以及您发布的信息，否则您应选择不发布。发布非匿名信息如发布帖子和评论等，视为您同意在平台向其他用户公开展示您的个人信息以及您发布的信息。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >通常情况下，我们会在以下场景中收集您的个人信息或申请相关权限：可查看上述</span
            ></strong
          ><span style="font-size: 14px; color: #333333"
            >【个人信息收集】、【权限调用】<strong>。</strong></span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >（一）注册、登录</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px">1</span
          ><span style="font-size: 14px"
            >、为接受骨转百讯全面的服务，您应首先登陆用户账号，为完善账号信息，您需至少提供如下信息：您准备使用</span
          ><span style="font-size: 14px">/</span
          ><span style="font-size: 14px"
            >修改的<strong>账号名、密码、您本人的手机号码（用于身份验证）。登陆</strong>成功后，您可选择完善相关的网络身份识别信息（包括头像、昵称、城市、个人简介、医师认证等相关信息），如您未完善，也不会影响您使用产品的基础功能。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
            text-align: justify;
          "
        >
          <span style="font-size: 14px">2</span
          ><span style="font-size: 14px"
            >、骨转百讯作为数字医疗健康平台，平台中大多数服务仅面向医疗卫生专业人士提供，且骨转百讯活动仅面向医疗卫生专业人士适用。同时用户在骨转百讯上发表的全部原创内容（包括但不仅限于回答、文章和评论），著作权和版权均归中关村精准医学基金会所有，因此<strong>在您需要使用相应的医疗专业服务、以医疗卫生专业人员身份发布内容/参与专区讨论、参加相应的活动时</strong>，为保障您专业人士身份的真实性从而保障内容的专业性/活动资格的真实性，<strong>平台有义务验证您的医疗卫生专业人士的身份</strong>，根据您选择身份认证的类型，我们可能需要收集您以下相关信息：</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
            text-align: justify;
          "
        >
          <span style="font-size: 14px"
            >（1）执业医师认证。您需要提供<strong>姓名、手机号、医院名称、科室、职称情况、职务、身份证号</strong>以供验证，可能还需要您继续提供<strong>工作胸牌/职称证、医师执业证、医师资格证（三选一）</strong>进行再次验证。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
            text-align: justify;
          "
        >
          <span style="font-size: 14px"
            >（3）其他医疗行业从业者认证。您需要提供<strong>基础信息（包括姓名、手机号、单位名称、部门、职务、身份证号码）、以及证件资料三选一（包括执业药师证、护士资格证、工作胸牌/其他）</strong>，并通过您提供的其他资料进行身份核对。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
            text-align: justify;
          "
        >
          <span style="font-size: 14px"
            >骨转百讯作为数字医疗健康平台，秉持专业负责的态度，我们将对您以医疗卫生专业人员身份发布的内容，参与的讨论进行区分管理，以此发挥您的专业和意见表达。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
            text-align: justify;
          "
        >
          <span style="font-size: 14px"
            >如您不同意提供验证信息或验证失败的，您将无法使用该等针对医疗专卫生专业人员提供的专业服务，同时您也不得以医疗卫生专业人员的身份在平台开展活动，但并不影响您作为普通用户使用平台的其他服务。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >（二）向您提供产品和服务</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >1</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、信息展示以及信息搜索浏览功能</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333">（</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >1</span
          ><span style="font-size: 14px; color: #333333"
            >）骨转百讯平台提供丰富的医疗健康相关的信息，这些信息包含文章、课程、视频、用户发布的帖子以及帖子中的评论等。为了向您提供更便捷、更优质的搜索服务，提升您的搜索体验，当您使用搜索功能时，我们会<strong>收集您的搜索关键词信息、日志记录</strong>。搜索关键词信息会暂时存储在您的设备中，当您在同一设备上再次使用搜索功能时，我们会向您展示您的搜索历史记录。<strong>您也可以在【历史搜索】中直接选择删除</strong>。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >当您浏览骨转百讯平台内容的过程中，我们会记录您的使用情况，并<strong
              >收集您的设备信息（设备品牌、设备型号、操作系统版本、应用程序版本）、</strong
            ></span
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >IP</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >地址、网络类型（</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >Wifi</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >信息）、浏览器类型、屏幕分辨率</span
            ></strong
          ><span style="font-size: 14px; color: #333333"
            >来对相应的内容或者服务进行排序以适应您的设备界面。为了向您提供搜索历史、浏览记录、收藏信息、订阅信息、订单信息，我们<strong>会收集您的浏览信息、搜索记录、收藏记录、订阅记录、订单信息</strong>。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >请您注意：</span
            ></strong
          ><span style="font-size: 14px; color: #333333"
            >单独的设备信息、浏览器类型信息或单独的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的设备信息、浏览器类型信息或搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，在结合使用期间，我们会将该等信息作为您的个人信息，按照本隐私政策对其进行处理与保护。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >2</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、发帖、评论、问答等信息公开发布功能</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >骨转百讯平台为用户提供如下信息发布功能：发帖，评论，评价、发布和上传视频，参与讨论话题、直播及其他信息发布功能公开发布信息。我们可能因此<strong>需要收集您的昵称、头像、账号、发布内容（包括文字、图片、视频等）</strong>。同时我们可能会根据您的账户类型及网络日志信息判断您是否可享受对应功能权限。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333">鉴于，</span>
          <span style="font-size: 14px; color: #333333"
            >骨转百讯提供的部分信息发布服务是<strong>专为医疗卫生专业人士发布专业医学内容信息或者病例信息进行同行分享与交流</strong>，因此我们可能会收集您的<strong>手机号码、所在城市，所在医院，科室、职称、您所发布的内容（包括患者年龄、患者性别、患者病情等）、本内容前述的浏览器类型、设备信息、日志信息</strong>。尤其是对于必须是由认证的用户才能参与的<strong
              >【查看</strong
            ></span
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >/</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >发布病例】</span
            ></strong
          ><span style="font-size: 14px; color: #333333"
            >的服务，<strong>我们要求您需要通过医疗卫生专业人士身份验证</strong>，如您未进行验证或拒绝进行验证的，您将无法使用该特别服务，但不影响您使用其它已提供相关个人信息可取得的服务。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >(</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >如您在评价时选择上传包含个人信息的图片</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >)</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >，或他人享有权利的信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若您公开发布的信息中涉及他人个人信息或他人享有权利的信息的，您需在发布前征得他人的同意。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >3</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、保障交易及运行安全服务</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >为提高您使用我们的产品与</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >/</span
          ><span style="font-size: 14px; color: #333333"
            >或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防钓鱼网站欺诈和保护账号安全<strong
              >，我们需要收集您的设备信息（设备品牌、设备型号、操作系统版本、应用程序版本）、</strong
            ></span
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >IP</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >地址、网络类型（</span
            ></strong
          ><strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >Wifi</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >信息）、浏览信息、订单信息、您常用的软件信息</span
            ></strong
          ><span style="font-size: 14px; color: #333333"
            >来识别是否为真实用户、是否为异常地点登录、判断您的账号风险，并可能会记录一些我们认为有风险的链接（</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >“URL”</span
          ><span style="font-size: 14px; color: #333333">）；</span>
          <span style="font-size: 14px; color: #333333"
            >我们也会收集您的设备信息对于骨转百讯系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用骨转百讯服务过程中对您的服务及账号安全进行保护。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >另外，<span style="text-decoration: underline"
              >我们可能需要收集您的设备</span
            ></span
          ><span style="text-decoration: underline"
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >Mac</span
            ></span
          ><span style="text-decoration: underline"
            ><span style="font-size: 14px; color: #333333">地址、</span></span
          ><span style="text-decoration: underline"> </span
          ><span style="text-decoration: underline"
            ><span style="font-size: 14px; color: #333333"
              >唯一设备识别（</span
            ></span
          ><span style="text-decoration: underline"
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >IMEI/android&nbsp;ID/IDFA/OPENUDID/GUID</span
            ></span
          ><span style="text-decoration: underline"
            ><span style="font-size: 14px; color: #333333">、</span></span
          ><span style="text-decoration: underline"
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >SIM</span
            ></span
          ><span style="text-decoration: underline"
            ><span style="font-size: 14px; color: #333333">卡</span></span
          ><span style="text-decoration: underline"
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >MSI</span
            ></span
          ><span style="text-decoration: underline"
            ><span style="font-size: 14px; color: #333333"
              >信息）、应用列表信息</span
            ></span
          ><span style="font-size: 14px; color: #333333"
            >，以更好地识别当前设备、</span
          >
          <span style="font-size: 14px; color: #333333"
            >分析当前设备的风险等级、识别各种作弊行为、识别风险设备，从而评估渠道质量，识别恶意刷量、恶意程序，提升反欺诈能力，保护您的账号安全。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span
              style="
                font-size: 14px;

                color: #333333;
              "
              >4</span
            ></strong
          ><strong
            ><span style="font-size: 14px; color: #333333"
              >、我们获取的设备权限</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >为向您提供便捷、优质的服务，我们需要您在您的设备中向我们开启<strong>您的相机（摄像头）、相册（图片库）、麦克风、系统通知的访问权限</strong>，以实现这些功能所涉及的信息的收集和使用。<strong>我们在您首次使用该类附加功能需开启相应权限时，弹窗对您进行提示，你选择开启相应权限，则可进一步使用相应服务。您可以在客户端或设备的设置功能中选择关闭部分或全部权限。</strong>在不同设备中，上述权限的开启和关闭方式可能有所不同，具体请参考设备及系统开发方的说明或指引。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >关于骨转百讯平台调用权限的具体情况，请见内容【权限调用清单】</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >请您注意：您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          ><span style="font-size: 14px; color: #333333"
            >若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span
            style="
              font-size: 14px;

              color: #333333;
            "
            >&nbsp;</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <strong
            ><span style="font-size: 14px; color: #333333"
              >（三）无需征得您同意的情形</span
            ></strong
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333"
            >根据相关法律的规定，我们在以下情况下收集和使用您的个人信息无需取得您的同意：</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333">（</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >1</span
          ><span style="font-size: 14px; color: #333333"
            >）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333">（</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >2</span
          ><span style="font-size: 14px; color: #333333"
            >）为履行法定职责或者法定义务所必需；</span
          >
        </p>
        <p
          style="
            margin-right: 0;
            margin-left: 0;
            font-size: 16px;

            text-wrap: wrap;
          "
        >
          <span style="font-size: 14px; color: #333333">（</span
          ><span
            style="
              font-size: 14px;

              color: #333333;
            "
            >3</span
          ><span style="font-size: 14px; color: #333333"
            >）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；</span
          >
        </p>
      </div></ItemScroll
    >
  </div>
</template>
<script>
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "privacyAgreement",
  components: {
    ItemScroll,
  },
};
</script>
<style >
.index_container {
  height: 100%;
  width: 100%;
  padding: 1rem;
}
.agreement_main {
  height: 100%;
}
.agreement ol li {
  margin-left: 40px;
}
.agreement p {
  margin: 1rem 0;
}
</style>
